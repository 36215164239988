$sm-screen: 35.5rem;
$md-screen: 48rem;
$lg-screen: 64rem;
$xl-screen: 80rem;

:root {
  --body-font-size: 16px;
  --body-line-height: 1.5;
  --body-color:       var(--oc-gray-9);
  --body-color-light: var(--oc-gray-6);
  --body-bg:          var(--oc-white);
  --link-color:       var(--oc-blue-9);
  --heading-color:    var(--oc-black);
  
  --body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --brand-title-font: "Abril Fatface", serif;
  --heading-font: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  --code-font: 'Courier New', Courier, monospace;

  --sidebar-color: var(--oc-gray-4);

  --message-color: var(--oc-white);
  --message-bg:    var(--oc-yellow-9);

  --border-radius: .5em;
  --border-color:  var(--oc-gray-3);

  --code-bg:       var(--oc-gray-1);
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-color:       var(--oc-gray-4);
    --body-color-light: var(--oc-gray-6);
    --body-bg:          var(--oc-gray-9);
    --link-color:       var(--oc-blue-4);
    --heading-color:    var(--oc-white);

    --message-color: var(--oc-gray-9);
    --message-bg:    var(--oc-yellow-4);

    --border-color:  var(--oc-gray-7);

    --code-bg:       var(--oc-gray-8);
  }
}
